<template>
  <div class="page">
    <div class="head d-flex justify-content-center align-items-center">
      <img src="@/assets/images/logo-full.png" class="img-fluid" alt="logo" width="180" @click="$router.push('/')" style="cursor: pointer;">
    </div>

    <div class="image-container">
      <!-- <img :src="creatorInfo.profile_banner" alt="Image"> -->
      <div class="w-100 img_wrap" :style="`height: 300px;background-size: cover;background-position: center center;background-image: url(${creatorInfo.profile_banner});`">
      </div>
    </div>
  </div>

  <section class="container" v-if="creatorInfo?.avatar">
    <div class="row">
      <div class="col-lg-9 col-md-12 col-sm-12 d-flex row">
        <div class="avatar-container d-flex col-lg-4 col-sm-12 col-md-12">
          <img :src="creatorInfo.avatar" alt="Image" class="img-fluid">
        </div>
        <div class="col-lg-8 col-md-12 col-sm-12 introduce">
          <h5>{{ creatorInfo.account }}</h5>
          <h1>{{ creatorInfo.user_name }}</h1>
          <p v-if="creatorInfo.personal_profile" v-html="creatorInfo.personal_profile.replaceAll('\n', '<br>')"></p>
          <p>{{ creatorInfo.post_total }} 貼文<span>&emsp;{{ creatorInfo.fans_total }} 訂閱</span></p>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 d-flex justify-content-center align-items-flex-start btn-box">
        <div v-if="creatorInfo.twitter" class="icon-container col-2">
          <a :href="creatorInfo.twitter">
            <img src="@/assets/images/promote/twitter.png" alt="icon" class="img-fluid" />
          </a>
        </div>
        <div v-if="creatorInfo.ig" class="icon-container col-2">
          <a :href="creatorInfo.ig">
            <img src="@/assets/images/promote/instagram.png" alt="icon" class="img-fluid" />
          </a>
        </div>
        <div v-if="creatorInfo.tik_tok" class="icon-container col-2">
          <a :href="creatorInfo.tik_tok">
            <img src="@/assets/images/promote/tik-tok.png" alt="icon" class="img-fluid" />
          </a>
        </div>
        <!-- <router-link :to="`/${creatorInfo.account}`" class="btn subscription">訂閱</router-link> -->
        <el-button @click="toLink(`/${creatorInfo.account}`)">訂閱</el-button>
      </div>
    </div>
  </section>

  <section class="container post-box" v-if="recommendList.length > 0">
    <h2 class="post">推薦貼文</h2>
    <div class="d-flex row">
      <div v-for="item in recommendList" :key="item.post_id" class="col-lg-4 col-md-6 col-sm-12 distance">
        <div class="post-container row">
          <img v-if="item.post_media_photo.length > 0" :src="item.post_media_photo[0].media_url" alt="icon" class="img-fluid" style="height: 180px;" />
          <div class="d-flex article-content">
            <div class="cars-avatar">
              <img :src="item.avatar" alt="Image" />
            </div>
            <div class="info">
              <span>{{ item.user_account }} <span>· {{ formatDate( `${item.created_date} ${item.created_time}` ) }}</span></span>
              <h6>{{ item.user_name }}</h6>
            </div>
          </div>
          <p>{{ item.post_content }}</p>
          <router-link target="_blank" :to="`/post/${item.post_id}?postType=5`" class="btn">查看更多</router-link>
        </div>
      </div>
    </div>
  </section>

  <section class="container limited-box" v-if="goodsList.length > 0">
    <h2 class="post">限量商品專區</h2>
    <div class="d-flex row">
      <div v-for="item in goodsList" :key="item.goods_id" class="col-lg-3 col-md-6 col-sm-12 distance">
        <div class="card" ref="cardRef">
          <!-- <img data-bs-toggle="modal" @click="fetchGoodsDetail(item.goods_id)" :src="item.goods_img" class="card-img-top " /> -->
          <div @click="fetchGoodsDetail(item.goods_id)" v-viewer class="rounded prod-img" data-bs-toggle="modal" :style="`background-image: url(${item.goods_img});width: ${clientWidth}px;height: ${clientWidth}px`">
            <!-- <img :src="item.goods_img" alt="" style="opacity: 0;"> -->
          </div>
          <div class="card-body">
            <h5 data-bs-toggle="modal" @click="fetchGoodsDetail(item.goods_id)" class="card-title d-inline-block text-truncate" style="max-width: 250px">
              {{ item.goods_name }}
            </h5>
            <div class="d-flex card-up">
              <div class="cars-avatar">
                <img :src="creatorInfo.avatar" alt="Image" />
              </div>
              <div class="info">
                <span>{{ item.account }}</span>
                <h6>{{ item.user_name }}</h6>
              </div>
            </div>
            <div class="d-flex buy-box">
              <div class="info">
                <span>{{ formatDate( `${item.created_at}` ) }}</span>
                <h6>{{ item.goods_price }}</h6>
                <span class="orign-price">{{ item.goods_original_price }}</span>
              </div>
              <div class="buy">
                <a @click="fetchGoodsDetail(item.goods_id)" href="javascript:void(0);" class="btn">購買</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <footer class="footer">
    <div class="">
      <p>Copyright © 2023 SeeWe</p>
    </div>
  </footer>

  <!-- <modal ref="modals" id="modalsDetail" dialogClass="modal-fullscreen-sm-down" tabindex="-1" :title="$t('label.products')+$t('label.detail')" aria-labelledby="modalsLabel" aria-hidden="true">
    <model-header>
      <h5 class="modal-title">{{$t('label.products')+$t('label.detail')}}</h5>
      <button :dismissable="true" class="btn btn-secondary" data-bs-dismiss="modal" aria-label="Close">
        <i class="ri-close-fill"></i>
      </button>
    </model-header>
    <model-body>
      <div v-if="goodsDetail.goods_name" class="border-bottom border-2 fw-bold p-2">
        <img :src="goodsDetail.goods_img" class="mb-3 block w-100">
        <p>商品名稱：{{ goodsDetail.goods_name }}</p>
        <p>商品描述：{{ goodsDetail.goods_desc }}</p>
        <p>價格：{{ goodsDetail.goods_price }}</p>
        <div>
          <div class="content-btn d-grid p-2">
            <button type="submit" class="btn btn-primary rounded-pill">付款</button>
          </div>
        </div>
      </div>
    </model-body>
  </modal> -->
  <!-- 收件人地址
   -->
  <el-dialog v-model="dialogVisible" fullscreen title="購買流程" width="30%">
    <div v-if="goodsDetail.goods_name" class=" border-2 fw-bold p-2">
      <div v-if="goodsDetail.goods_name" class="goods-top">
        <div class="d-flex justify-content-center align-content-center pb-3">
          <div class="rounded">
            <img class="rounded" :src="goodsDetail.goods_img">
          </div>
          <!-- <div class="prod-img" :style="`background-image: url(${goodsDetail.goods_img});`"></div> -->
        </div>
        <p>商品名稱：{{ goodsDetail.goods_name }}</p>
        <p>價格：<span class="pqTWkA">{{ goodsDetail.goods_price }}</span></p>
        <div class="U9rGd1">
          <h2 class="Iv7FJp">商品詳情</h2>
          <div class="p-2 goods_detail">
            <p v-html="goodsDetail.goods_desc"></p>
          </div>
        </div>
        <hr>
      </div>
      <div class="U9rGd1">
        <h2 class="Iv7FJp">收件人資訊 <span class="text-danger">(收件人身分證件上之真實姓名)</span></h2>
        <el-form ref="ruleFormRef" :model="postForm">
          <div class="row align-items-center pt-1">
            <el-form-item label="" prop="rv_name" :rules="[{ required: true, message: '請輸入收件人姓名', trigger: 'blur' }]">
              <el-input v-model="postForm.rv_name" type="text" class="mb-2 ps-1" placeholder="收件人姓名" />

            </el-form-item>
          </div>
          <div class="row align-items-center">
            <el-form-item label="" prop="rv_mobile" :rules="[{ required: true, message: '請輸入收件人電話', trigger: 'blur' }]">
              <el-input v-model="postForm.rv_mobile" type="text" class="mb-2 ps-1" placeholder="收件人電話" />
            </el-form-item>
          </div>
          <div class="row align-items-center">
            <el-form-item label="" prop="rv_email" :rules="[
              {required: true, message: '請輸入收件人信箱', trigger: 'blur' },
              {type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
            ]">
              <el-input v-model="postForm.rv_email" type="text" class="mb-2 ps-1" placeholder="收件人信箱" />
            </el-form-item>
          </div>
          <!-- {{ postForm }} -->
        </el-form>
      </div>
      <div class="U9rGd1">
        <h2 class="Iv7FJp">配送方式</h2>
        <div class="row align-items-center content-btn d-grid ps-2 pe-2">
          <div class="d-flex justify-content-center align-content-center p-2">
            <img style="width: 20%;" src="@/assets/images/1697562822924.png" alt="" />
          </div>
          <div v-if="checkoutInfo.stName" class="d-flex justify-content-between ps-1 order-info-text">
            <p>門市</p>
            <p>{{ checkoutInfo.stName }}</p>
          </div>
          <div v-if="checkoutInfo.stName" class="d-flex justify-content-between ps-1 order-info-text">
            <p>門市代碼</p>
            <p>{{ checkoutInfo.st_code }}</p>
          </div>
          <div v-if="checkoutInfo.stName" class="d-flex justify-content-between ps-1 order-info-text">
            <p>電話</p>
            <p>{{ checkoutInfo.stTel }}</p>
          </div>
          <div v-if="checkoutInfo.stName" class="d-flex justify-content-between ps-1 order-info-text">
            <p>地址</p>
            <p>{{ checkoutInfo.rstore_addr }}</p>
          </div>
          <el-button v-if="!checkoutInfo.stName" @click="toMap" type="success " plain>選擇超商門市</el-button>
          <div v-else class="d-flex justify-content-end ps-1 order-info-text">
            <el-button @click="toMap" type="danger" :icon="Edit" link>修改門市</el-button>
          </div>

        </div>
      </div>
      <div class="U9rGd1">
        <h2 class="Iv7FJp">付款方式</h2>
        <div class="row align-items-center pt-1">
          <el-select class="w-100" placeholder="請選擇付款方式" v-model="postForm.selectedPayMethod">
            <el-option v-for="item in PayOptions" :key="item.value" :value-key="value" :label="item.label" :value="item.value" />
          </el-select>
        </div>
      </div>
      <div class="U9rGd1">
        <h2 class="Iv7FJp">付款詳情</h2>
        <div class="d-flex justify-content-between ps-1 order-info-text pt-1">
          <p>商品金額</p>
          <p>{{goodsDetail.goods_price}}</p>
        </div>
        <div class="d-flex justify-content-between ps-1 order-info-text">
          <p>運費</p>
          <p>{{goodsDetail.shipping_fee}}</p>
        </div>
        <div class="d-flex justify-content-between ps-1 order-info-text">
          <p>總計</p>
          <p>{{goodsDetail.amount}}</p>
        </div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <div>
          <div class="content-btn d-grid p-2">
            <el-button :loading="onLoading" @click="submitCheckout" type="primary" class="btn btn-primary rounded-pill">結帳</el-button>
          </div>
        </div>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { Edit } from '@element-plus/icons-vue'
import { checkout } from '@/api/myPay'
import {
  setLocalStorageClient,
  getLocalStorageClient,
  hasLocalStorageClientKeyToString
} from '@/utils/assist'
import { ref, onMounted, reactive, getCurrentInstance, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useDate } from '@/utils/useDate'
const store = useStore()
const route = useRoute()
const router = useRouter()
// const user = ref({})
import { getRecommendList } from '@/api/post'
import { getTransaction } from '@/api/ezship'

import { getGoods, getGoodsDetail } from '@/api/goods'
import { getCreator } from '@/api/user'
import { useCheckout } from '@/utils/useCheckout'

const { proxy } = getCurrentInstance()

const creatorInfo = ref({})
const goodsList = ref([])
const recommendList = ref([])
const goodsDetail = ref({})
const dialogVisible = ref(false)
const checkoutInfo = ref({})
const { PayOptions } = useCheckout()
const ruleFormRef = ref(null)

const onLoading = ref(false)

const postForm = ref({
  goods_id: '',
  rv_name: '',
  rv_email: '',
  rv_mobile: '',
  selectedPayMethod: 'ewallet'
})
const query = route.query
if (!query.user_account) {
  router.push({ name: 'auth1.sign-up' })
}
const $toast = proxy.$toast

const { formatDate } = useDate()
const goodsParams = reactive({
  page: 1,
  page_size: 100,
  sort_by: 'desc',
  order_by: 'created_at',
  is_public: 1
})
const toLink = val => {
  store.dispatch('Setting/setShopUrlAction', route.fullPath)
  router.push({ path: val })
}
const recommendParams = reactive({
  page: 1,
  page_size: 100,
  sort_by: 'asc',
  order_by: 'created_at'
})

const fetchRecommendCreator = query => {
  let param = {
    account: query.user_account
  }
  getCreator(param)
    .then(res => {
      creatorInfo.value = res.user
    })
    .catch(err => {
      console.log(err)
    })
}

const cardRef = ref(null)
const clientWidth = ref(300)

const doResize = () => {
  clientWidth.value = cardRef.value[0].clientWidth
}
const fetchGoods = goodsParams => {
  let param = {
    ...goodsParams,
    account: query.user_account
  }
  getGoods(param)
    .then(res => {
      goodsList.value = res.data
      setTimeout(() => {
        clientWidth.value = cardRef.value[0].clientWidth
      }, 100)
    })
    .catch(err => {
      console.log(err)
    })
}
const fetchRecommendList = data => {
  let param = {
    ...data,
    account: query.user_account
  }
  getRecommendList(param)
    .then(res => {
      recommendList.value = res.data
    })
    .catch(err => {
      console.log(err)
    })
}
const fetchGoodsDetail = goods_id => {
  onLoading.value = true
  postForm.value.goods_id = goods_id
  getGoodsDetail(goods_id)
    .then(res => {
      goodsDetail.value = res.data
      dialogVisible.value = true
      onLoading.value = false
      setTimeout(() => {
        appendImagedth()
      }, 1000);
    })
    .catch(err => {
      console.log(err)
    })
}

const appendImagedth = () => {
  // 抓取 class 為 'goods_detail' 的元素
  let goodsDetail = document.querySelector('.goods_detail')

  // 如果元素存在，則設置其下所有圖片的寬度為 100%
  if (goodsDetail) {
    let images = goodsDetail.querySelectorAll('img')
    images.forEach(function(img) {
      img.style.width = '100%'
    })
  }
}
// const getUserInfo = async () => {
//   await store.dispatch('User/fetchUserAction')
//   user.value = store.getters['User/userState']
// }
const toMap = async () => {
  // 現在你可以在 URL 中使用 urlEncodedString
  setLocalStorageClient('postForm', postForm.value)
  const url =
    process.env.VUE_APP_AXIOS_BASEURL +
    '/ezship/map?to_front_url=' +
    process.env.VUE_APP_FRONT_END_URL +
    // 'http://localhost:8082' +
    'auth/home/?user_account=' +
    query.user_account +
    '&json='
  console.log(url)
  location.href = url
}

const submitCheckout = async () => {
  ruleFormRef.value.validate(valid => {
    console.log(ruleFormRef)
    console.log(valid)
    if (!valid) return

    checkoutInfo.value.rv_name = postForm.value.rv_name
    checkoutInfo.value.rv_mobile = postForm.value.rv_mobile
    checkoutInfo.value.rv_email = postForm.value.rv_email

    const d = {
      check_type: postForm.value.selectedPayMethod,
      id: postForm.value.goods_id,
      type: 'goods',
      number: 1,
      redirect_url: process.env.VUE_APP_FRONT_END_URL + '/user/order-buy', // 是訂閱，完成以後轉向創作者首頁
      ezship: { ...checkoutInfo.value }
    }
    console.log(d)
    onLoading.value = true
    checkout(d)
      .then(res => {
        //console.log(res)
        if (res.code == 200) {
          location.href = res.data.url
        }
        if (res.code == 250) {
          $toast.show('完成付款')

          setTimeout(() => {
            onLoading.value = false
            // 获取当前页面的 URL
            const currentUrl = window.location.href
            // 解析 URL，获取参数
            const urlParams = new URLSearchParams(window.location.search)
            // 移除指定的参数
            urlParams.delete('order_sn')
            // 重新构建 URL
            var newUrl = currentUrl.split('?')[0] + '?' + urlParams.toString()
            // 使用新的 URL 重定向到页面
            window.location.href = newUrl
          }, 2000)
        }
        action.value = res.action
      })
      .catch(err => {
        $toast.show(err.response.data.message)
        //console.log(err)
        onLoading.value = false
      })
  })
}

onMounted(async () => {
  // await getUserInfo()
  fetchRecommendCreator(query)
  fetchGoods(goodsParams)
  fetchRecommendList(recommendParams)
  store.dispatch('Setting/setShopUrlAction', '')
  if (query.order_sn) {
    let param = {
      order_sn: query.order_sn
    }
    getTransaction(param)
      .then(res => {
        console.log(res)
        if (hasLocalStorageClientKeyToString('postForm')) {
          postForm.value = getLocalStorageClient('postForm')
        }
        checkoutInfo.value = res.data
        // debugger
        console.log('postForm', postForm.value)
        fetchGoodsDetail(postForm.value.goods_id)
      })
      .catch(err => {
        console.log(err)
      })
  }

  window.addEventListener('resize', doResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', doResize)
})
</script>
<style scoped>
.swiper-area {
  overflow-x: hidden;
}

body {
  background-color: #f9f7f4 !important;
  font-family: Microsoft JhengHei;
}

.page {
  position: relative;
}

.head {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; /* 让 head 悬浮在最上层 */
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #cccccc99;
  padding: 5px;
}

.image-container {
  position: relative;
  width: 100%;
  max-height: 300px; /* 限制容器的最大高度 */
}

.image-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.avatar-container {
  padding-left: 25px;
  justify-content: center !important;
}

.avatar-container img {
  border-radius: 30px;
  height: 160px;
  /* object-fit: cover; */
  border: 10px solid #fff;
  position: relative;
  bottom: 100px;
}

.introduce h5 {
  padding-top: 10px;
  font-weight: bold;
}

.introduce h1 {
  font-weight: bold;
  font-size: 40px;
}

.introduce p {
  font-weight: 600;
  font-size: 16px;
  color: #4d4d4d;
  margin-bottom: 0;
}

.introduce {
  padding-left: 30px;
}

.icon-container img {
  border: 2px solid #cccccc;
  padding: 5px;
  border-radius: 10px;
}

.icon-container {
  padding-right: 8px;
}

.subscription {
  border: 2px solid #cccccc;
  background-color: transparent;
  border-radius: 5px;
  max-height: 19%;
}

.btn-box {
  padding-top: 15px;
}

@media only screen and (max-width: 991px) {
  .subscription {
    max-height: none;
    padding-top: 14px;
  }

  .post {
    padding-top: 20px;
  }
  .introduce {
    margin-top: -90px;
  }
}

.post {
  font-weight: bold;
}

.post-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
}

.distance {
  padding: 12px;
}

.article-content {
  padding-top: 10px;
}

.article-content img {
  border-radius: 20px;
  position: relative;
}

.article-content p {
  font-size: 18px;
  font-weight: 800;
  color: #000;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
}

.post-container img {
  border-radius: 20px;
}

.post-container p {
  padding-top: 10px;
  font-size: 17px;
  width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
}

.post-container a {
  border: none;
  background-color: #333333;
  color: #fff;
  border-radius: 20px;
}

.post-box {
  border-bottom: 1.5px solid #b3b3b3;
}

.limited-box {
  padding-top: 20px;
}

.card-title {
  color: #000;
  font-weight: 800;
}

.fixed-height-img {
  height: 200px;
  object-fit: cover;
}

.card-up {
  border-bottom: 1.5px solid #b3b3b3;
  padding-bottom: 10px;
}

.cars-avatar img {
  height: 60px;
  object-fit: cover;
  border-radius: 15px;
  margin-right: 12px;
}

.info span {
  color: #7a798a;
  line-height: 21px;
  font-size: 13px;
}

.info h6 {
  line-height: 22px;
  color: #000;
  font-size: 18px;
  font-weight: bold;
}

.buy {
  padding-top: 5px;
}

.buy a {
  background-color: #333333;
  color: #fff;
  border-radius: 7px;
  padding: 1px 20px 3px 20px;
}

.buy-box {
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.footer {
  background-color: #b3b3b3;
  padding-bottom: 10px;
  margin-top: 70px;
}

.footer p {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  margin-bottom: 0;
}
.orign-price {
  text-decoration: line-through;
}
.order-info-text p {
  margin-bottom: 0.3rem;
  color: #606266a3;
  font-weight: 500;
}
.goods-top p {
  margin-bottom: 0.3rem;
  font-weight: 500;
}
.goods_detail img {
  width: 100%;
}
@media screen and (max-width: 400px) {
  .img_wrap {
    height: 200px !important;
  }
}
</style>
